import './App.css';
import bbIcon from './static/logo512.png'

function App() {
  return (
    <div className="App">
      <header className="App-content">
        <img src={bbIcon} alt="BeerBelly" className="belly-icon" />
        <p>
          Future Home of Belly...
        </p>
      </header>
    </div>
  );
}

export default App;
